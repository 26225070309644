@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Tajawal", sans-serif;
  
}

.container {
  padding: 0 1.063rem;
}

.custom-select {
  appearance: none; /* Remove the default arrow icon */
  background-image: url("./Assets/Icons/arrowDown.png"); /* Replace with the path to your custom arrow icon image */
  background-size: 15px; /* Adjust the size as needed */
  background-position: left center;
  background-repeat: no-repeat;
}

.form-checkbox {
  border-radius: 5px;
  margin-left: 7px;
  margin-top: -4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  line-height: 1em;
  background: #eaecee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-checkbox:checked:after {
  content: "✔";
  color: #000;
  margin-top: 5px;
}
